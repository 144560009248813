import { AppState } from 'store';
import { Sjekkpunkt } from './sjekkpunkter/types';
import { Tilstandsrapport } from './tilstandsrapport/types';

/* eslint-disable import/prefer-default-export */

export const getTilstandsrapport = (state: AppState, id: string): Tilstandsrapport =>
  state.tilstandsrapportEntities.tilstandsrapport[id];

export const getSjekkpunkter = (state: AppState): Sjekkpunkt[] => state.tilstandsrapportEntities.sjekkpunkter;

// TODO: As we're just loading one report at the time, allIds[0] is currently the active one.
// Needs to be refactored once we plan on loading multiple tilstandsrapporter.
// I.e { report: { visibleTilstandsrapportId: ID } }
export const getActiveTilstandsRapport = (state: AppState): string => {
  try {
    const {
      report: { activeReportId = undefined },
    } = state;
    return activeReportId;
  } catch (e) {
    return null;
  }
};
