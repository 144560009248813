import btoa from 'btoa';
import getConfig from 'next/config';
import { createSelector } from 'reselect';
import { AppState } from 'store';
import { getSjekkpunkter, getTilstandsrapport } from 'store/tilstandsrapportEntities/selectors';
import { getFloor } from 'store/tilstandsrapportEntities/sjekkpunkter/selectors';
import { Sjekkpunkt } from 'store/tilstandsrapportEntities/sjekkpunkter/types';
import { getPersonalFinanceData } from 'store/user/personalFinance/selectors';
import { toTitleCase } from 'store/utils';
import { LoanInformation } from './types';

const tgSortingFunction = (a, b): number => {
  if (a.tg === b.tg) return 0;
  if (a.tg < b.tg) {
    return 1;
  }
  return -1;
};

const getTilstandsrapportSjekkpunkterIds = (state: AppState, id: string): number[] => {
  const tilstandsrapport = state.tilstandsrapportEntities.tilstandsrapport[id];
  return (tilstandsrapport && tilstandsrapport.sjekkpunkter) || [];
};

export const getAddress = (state: AppState, id: string): string => {
  const tilstandsrapport = state.tilstandsrapportEntities.tilstandsrapport[id];
  return (
    (tilstandsrapport && toTitleCase(state.tilstandsrapportEntities.tilstandsrapport[id].matrikkelId.adresse)) || null
  );
};

const getTilstandsrapportEiendom = createSelector(
  [getTilstandsrapport],
  (tilstandsrapport) => (tilstandsrapport && tilstandsrapport.eiendom) || null,
);

export const getFellesutgifter = createSelector(
  [getTilstandsrapportEiendom],
  (eiendom) => (eiendom && eiendom.fellesutgifter) || null,
);

export const getKommunaleAvgifter = createSelector(
  [getTilstandsrapportEiendom],
  (eiendom) => (eiendom && eiendom.kommunaleAvgifter && eiendom.kommunaleAvgifter / 12) || null,
);

export const getBoligtype = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  if (tilstandsrapport) {
    if (tilstandsrapport.eiendom && tilstandsrapport.eiendom.typeEiendom) {
      return tilstandsrapport.eiendom.typeEiendom;
    }
    return tilstandsrapport.type !== '' ? tilstandsrapport.type : null;
  }
  return null;
});

export const getIsVitec = createSelector(
  [getTilstandsrapportEiendom],
  (eiendom) => eiendom && eiendom.kildesystem === 'VITEC',
);

export const getShowLivingCost = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  const isObos = tilstandsrapport && tilstandsrapport.eiendom && tilstandsrapport.eiendom.meglerkjedeId === '196';
  return !isObos;
});

export const getShowMarkedLink = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  // TODO add correct filters here when provided by Vendu
  // return tilstandsrapport && tilstandsrapport.id === "5bfaa87e82ee3a6e00fba9f2";
  return false;
});

// Selectors
const formatAwsImageString = (key, width, resizeFit = 'inside'): string => {
  const {
    publicRuntimeConfig: { awsBucket, cloudFrontURL },
  } = getConfig();

  return `${cloudFrontURL}/${btoa(
    JSON.stringify({
      key,
      bucket: awsBucket,
      edits: {
        jpeg: {
          quality: 80,
        },
        resize: {
          width,
          fit: resizeFit,
        },
      },
    }),
  )}`;
};

export const makeGetImages = () => {
  return createSelector([getTilstandsrapportEiendom, getBoligtype, getIsVitec], (eiendom, boligtype, isVitec) => {
    if (!eiendom || (eiendom.bilder && eiendom.bilder.length === 0)) {
      return /enebolig|rekkehus|tomannsbolig/i.test(boligtype)
        ? [
            {
              large: '/images/enebolig-fallback.jpg',
              medium: '/images/enebolig-fallback.jpg',
              small: '/images/enebolig-fallback.jpg',
            },
          ]
        : [
            {
              large: '/images/leilighet-fallback.jpg',
              medium: '/images/leilighet-fallback.jpg',
              small: '/images/leilighet-fallback.jpg',
            },
          ];
    }

    if (eiendom.bilder) {
      if (isVitec) {
        return eiendom.bilder.map((b) => {
          const key = b.url.replace('https://meglerservice.s3.amazonaws.com/', '');
          return {
            large: formatAwsImageString(key, 1000),
            medium: formatAwsImageString(key, 768),
            small: formatAwsImageString(key, 414),
          };
        });
      }
      return eiendom.bilder.map((b) => ({
        large: encodeURI(b.url),
        medium: encodeURI(b.url),
        small: encodeURI(b.url),
      }));
    }

    return [];
  });
};

export const getTilstandsrapportSjekkpunkter = createSelector(
  [getSjekkpunkter, getTilstandsrapportSjekkpunkterIds],
  (sjekkpunkter, sjekkpunktIds) => sjekkpunktIds.map((sId) => sjekkpunkter[sId]),
);

export const getAllBuildings = createSelector([getTilstandsrapportSjekkpunkter], (sjekkpunkter) => {
  const buildings = new Set();
  sjekkpunkter.forEach((sp) => {
    if (sp.grupper && sp.grupper.length) buildings.add(sp.grupper[0]);
  });
  return Array.from(buildings);
});

export const makeGetTgCounts = () => {
  return createSelector(
    [getTilstandsrapportSjekkpunkter],
    sp => {
      const tgMap = sp.reduce((acc, curr: Sjekkpunkt) => {
        if (typeof acc[curr.tg] === 'undefined') {
          acc[curr.tg] = 1;
        } else {
          acc[curr.tg] += 1;
        }
        return acc;
      }, {});
      return {
        tg1: (tgMap[1] || 0) + (tgMap[0] || 0),
        tg2: tgMap[2] || 0,
        tg3: tgMap[3] || 0,
        tgiu: tgMap[-1] || 0,
      };
    },
  );
};

export const getGroupedSjekkpunkterByKategoriId = createSelector(
  (state, tilstandsrapportId, kategoriId) => getTilstandsrapportSjekkpunkter(state, tilstandsrapportId),
  (state, tilstandsrapportId, kategoriId) => kategoriId,
  (state, tilstandsrapportId, kategoriId) => state,
  (sjekkpunkter, kategoriId, state) => {
    return sjekkpunkter
      .filter((sp) => {
        return sp.kategoriId === kategoriId;
      })
      .reduce((acc, curr) => {
        const floor = getFloor(state, curr.id) || 'Ikke satt';
        if (!acc[floor]) acc[floor] = [];
        acc[floor].push(curr);
        return acc;
      }, {});
  },
);

export const getSize = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  if (!tilstandsrapport) return null;
  if (tilstandsrapport.bra) return tilstandsrapport.bra;
  return tilstandsrapport.eiendom ? tilstandsrapport.eiendom.prom : null;
});

export const getByggear = (state: AppState, id: string): number => {
  return (
    state.tilstandsrapportEntities.tilstandsrapport[id] && state.tilstandsrapportEntities.tilstandsrapport[id].byggear
  );
};

export const getIsWebtakst = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  return tilstandsrapport && tilstandsrapport.kildesystem === 'WEBTAKST';
});

export const getIsNorskTakst = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  return tilstandsrapport && (tilstandsrapport.kildesystem === 'IVIT' || tilstandsrapport.kildesystem === 'ETIQ');
});

export const getCriticalSjekkpunkterIds = createSelector([getTilstandsrapportSjekkpunkter], (sjekkpunkter) =>
  sjekkpunkter
    .filter((sp) => sp.utbedringData && sp.utbedringData.critical && sp.tg !== -1)
    .sort(tgSortingFunction)
    .map((sp) => sp.id),
);

export const getOtherSjekkpunkterIds = createSelector([getTilstandsrapportSjekkpunkter], (sjekkpunkter) =>
  sjekkpunkter
    .filter((sp) => sp.utbedringData && (!sp.utbedringData.critical || sp.tg === -1))
    .sort(tgSortingFunction)
    .map((sp) => sp.id),
);

export const getAllSjekkpunkterIds = createSelector([getTilstandsrapportSjekkpunkter], (sjekkpunkter) =>
  sjekkpunkter.sort(tgSortingFunction).map((sp) => sp.id),
);

const getTilstandsrapportLoanInformation = (state: AppState, id: string): LoanInformation =>
  state.tilstandsrapportEntities.tilstandsrapport[id].userData.loanInformation;

// Cost selectors
export const getBokostnader = createSelector(
  [getTilstandsrapport],
  (tilstandsrapport) => tilstandsrapport.bokostnader || null,
);

// Todo: See readme regarding loanInformation
export const getTilstandsrapportUserData = createSelector([getTilstandsrapport], (tilstandsrapport) => {
  return tilstandsrapport.userData || null;
});

export const getUserLoanInformation = createSelector([getTilstandsrapportUserData], (userData) =>
  userData && userData.loanInformation ? userData.loanInformation : null,
);

export const getMonthlyLivingCost = createSelector(
  [getBokostnader, getTilstandsrapportEiendom],
  (bokostnader, eiendom) => {
    return Math.round(
      (bokostnader?.stroemforbruk || 0) +
        (bokostnader?.husforsikring || 0) +
        (bokostnader?.innboforsikring || 0) +
        (bokostnader?.vedlikeholdskostnader || 0) +
        (eiendom?.fellesutgifter || 0) +
        ((eiendom && eiendom.kommunaleAvgifter / 12) || 0),
    );
  },
);

export const getMeglerkjedeId = createSelector([getTilstandsrapportEiendom], (eiendom) => {
  return eiendom && eiendom.meglerkjedeId ? eiendom.meglerkjedeId : null;
});

export const getKontorId = createSelector([getTilstandsrapportEiendom], (eiendom) =>
  eiendom && eiendom.kontorId ? eiendom.kontorId : null,
);

export const getKontorNavn = createSelector([getTilstandsrapportEiendom], (eiendom) =>
  eiendom && eiendom.kontorNavn ? eiendom.kontorNavn : null,
);

// Do not show utbedringsanalyse for Aktiv.
// TODO: Configgify this!
const ignoreUtbedringsanalyseMeglerkjedeIds = ['194', '195'];
export const getShowUtbedringsanalyse = createSelector([getTilstandsrapportEiendom], (eiendom) => {
  return !eiendom || ignoreUtbedringsanalyseMeglerkjedeIds.indexOf(eiendom.meglerkjedeId) === -1;
});

export const getMonthlyLoanCost = createSelector([getTilstandsrapportLoanInformation], (loanInformation) => {
  if (
    loanInformation &&
    loanInformation.length &&
    loanInformation.interest &&
    loanInformation.paymentCost &&
    loanInformation.amount
  ) {
    const interestMonthly = loanInformation.interest / 100 / 12;
    const numberOfMonths = loanInformation.length * 12;
    const interestMultiplier = 1 + interestMonthly;
    const numerator = interestMonthly * interestMultiplier ** numberOfMonths;
    const denominator = interestMultiplier ** numberOfMonths - 1;

    return Math.round((loanInformation.amount * numerator) / denominator) + loanInformation.paymentCost;
  }
  return 0;
});

export const getLoanInformationFormValuesLength = createSelector(
  [getTilstandsrapportLoanInformation],
  (loanInformation) => {
    if (loanInformation) {
      return Object.keys(loanInformation).filter((key) => {
        return loanInformation[key] !== null && !Number.isNaN(loanInformation[key]);
      }).length;
    }
    return 0;
  },
);

export const getDisposableIncome = createSelector(
  [getMonthlyLoanCost, getMonthlyLivingCost, getPersonalFinanceData],
  (monthlyLoanCost, monthlyLivingCost, personalFinance) => {
    return (
      personalFinance.salary -
      monthlyLivingCost -
      monthlyLoanCost -
      personalFinance.otherPayments -
      personalFinance.otherInsurance -
      personalFinance.saving
    );
  },
);
