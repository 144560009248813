import { createSelector } from 'reselect';
import { AppState } from 'store';
import { PersonalFinance } from './types';

/* eslint-disable import/prefer-default-export */

export const getPersonalFinanceData = (state: AppState): PersonalFinance => state.user.personalFinance;

export const getPersonalFinanceFormValuesLength = createSelector(
  [getPersonalFinanceData],
  personalFinance => {
    if (personalFinance) {
      return Object.keys(personalFinance)
        .map(key => {
          return !Number.isNaN(personalFinance[key]) && typeof personalFinance[key] === 'number';
        })
        .filter(Boolean).length;
    }
    return 0;
  },
);
