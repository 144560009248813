// State
export interface ReportState {
  pending: boolean;
  error: Error;
  activeReportId: string;
  compareReportId: string;
}

// Actions
// eslint-disable-next-line import/prefer-default-export
export enum ReportActionTypes {
  REQUEST = '@@analyse/REQUEST',
  SUCCESS = '@@analyse/SUCCESS',
  FAILURE = '@@analyse/FAILURE',
  SET_ACTIVE_REPORT = '@@analyse/SET_ACTIVE_REPORT',
  SET_COMPARE_REPORT = '@@analyse/SET_COMPARE_REPORT',
}

// Action Creators
interface ReportRequest {
  type: typeof ReportActionTypes.REQUEST;
}

interface ReportSuccess {
  type: typeof ReportActionTypes.SUCCESS;
}

interface ReportFailure {
  type: typeof ReportActionTypes.FAILURE;
  error: Error;
}

interface SetActiveReport {
  type: typeof ReportActionTypes.SET_ACTIVE_REPORT;
  tilstandsrapportId: string;
}

interface SetCompareReport {
  type: typeof ReportActionTypes.SET_COMPARE_REPORT;
  tilstandsrapportId: string;
}

export type ReportActionCreators = ReportRequest | ReportSuccess | ReportFailure | SetActiveReport | SetCompareReport;
