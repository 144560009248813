/* eslint-disable import/prefer-default-export */

export enum TilstandsrapportEntitiesActionTypes {
  ADD = '@@tilstandsrapportEntities/ADD',
}

interface TilstandsrapportEntitiesAdd {
  type: typeof TilstandsrapportEntitiesActionTypes.ADD;
  data: {};
}

export type TilstandsrapportEntitiesActionCreators = TilstandsrapportEntitiesAdd;
